import React from 'react';
import './index.css';
import App from './App';
import WebFont from 'webfontloader';

import ReactDOM from 'react-dom';
WebFont.load({
    google: {
        families: ['Raleway:300,400,500,600,700', 'Open Sans:300,400,500,600,700',
            'Arima Madurai', 'PT Sans', 'Lora'],
        urls: ['./assets/font']
    }
});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
