import React, {useCallback} from 'react';
import './header.css'
import logo from '../../assets/galleriPilehøj_Visitkort.86bc9852.jpg'
import Cart from "../cart/Cart";
import {Link} from "react-router-dom";

export default function Header({displayCart = false}) {
    const onClicked = useCallback(() => {
        window.location = ''
    }, []);

    return [<div className='header' key='header'>
        <div className='welcome-wrapper' onClick={onClicked} style={{cursor: 'pointer'}}>
            <h1>We are Galleri Pilehøj</h1>
            {/*<p>Closed for 2023 - see you next year!</p>*/}
            <p>Friday, Saturday, Monday: 12-16</p>
        </div>
        <img
            srcSet={'https://storage.googleapis.com/main-frontpage-images/443/logo@1.webp 2670w,https://storage.googleapis.com/main-frontpage-images/443/logo@2.webp 1335w,https://storage.googleapis.com/main-frontpage-images/443/logo@4.webp 667w,https://storage.googleapis.com/main-frontpage-images/443/logo@6.webp 445w,https://storage.googleapis.com/main-frontpage-images/443/logo@8.webp 333w'}
            sizes={'(max-width: 300px) 200px, (max-width: 600px) 200px, (max-width: 1500px) 100px, 100px'}
            alt='logo' onClick={onClicked} style={{cursor: 'pointer'}}/>
        {/*<div className='menu-wrapper'>*/}
        {/*    <Link to='/blog'>/blog</Link>*/}
        {/*    <Link to='/'>/home</Link>*/}
        {/*    /!*<Link to='/products'>/products</Link>*!/*/}
        {/*</div>*/}
    </div>, displayCart ? <Cart key='cart'/> : '']
}
