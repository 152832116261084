import React from 'react';
import './footer.css'
import mail from '../../assets/icon/bx-mail-send.svg'
import phone from '../../assets/icon/bxs-phone.svg'
import map from '../../assets/icon/bx-map.svg'
import fb from '../../assets/icon/facebook.svg';

export default function Footer() {
    return <div className='footer'>
        <div className='part-wrapper'>
            <a href='tel:22572045'>
                <img src={phone} alt='Ring gerne hvis du vil sige hej'/>
                <span>29849417</span>
            </a>
            <a href='https://maps.google.com/?q=Galleri pilehøj østermarie'>
                <img src={map} alt='Ellers må i komme forbi'/>
                <span>Savmøllevej 10, 3751 Østermarie</span>
            </a>
            <a href='mailto:hej@galleripilehoj.dk'>
                <img src={mail} alt='Skriv endelig til os!'/>
                <span>hej@galleripilehoj.dk</span>
            </a>
            <a href='https://www.facebook.com/Galleripilehoj'>
                <img src={fb} alt='Facebook'/>
                facebook
            </a>
        </div>
    </div>
}
