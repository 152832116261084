import React from 'react'
import './k-tabs.css'

export default function KTabs({tabTitles, selected, setSelected}) {
    return (<div className='k-tabs'>
        {tabTitles.map(t => {
            return <div
                key={t}
                onClick={() => setSelected(t)}
                className={`${selected === t ? 'selected' : ''} tab-container`}>
                <span>{t}</span>
            </div>
        })}
    </div>)
}
