import React, {useState} from "react";
import './grid-gallery.css'
import PopUp from "../popup-window/PopUp";

export default function GridGallery() {
    const withSrcset = [
        {
            "id": 268,
            "src_set": "https://storage.googleapis.com/main-frontpage-images/268/178886868_1223659171387014_8474470958417737809_n@1.webp 1169w,https://storage.googleapis.com/main-frontpage-images/268/178886868_1223659171387014_8474470958417737809_n@2.webp 584w,https://storage.googleapis.com/main-frontpage-images/268/178886868_1223659171387014_8474470958417737809_n@4.webp 292w,https://storage.googleapis.com/main-frontpage-images/268/178886868_1223659171387014_8474470958417737809_n@6.webp 194w,https://storage.googleapis.com/main-frontpage-images/268/178886868_1223659171387014_8474470958417737809_n@8.webp 146w"
        }, {
            "id": 267,
            "src_set": "https://storage.googleapis.com/main-frontpage-images/267/180079652_122700893151171_3464228115883829878_n@1.webp 1169w,https://storage.googleapis.com/main-frontpage-images/267/180079652_122700893151171_3464228115883829878_n@2.webp 584w,https://storage.googleapis.com/main-frontpage-images/267/180079652_122700893151171_3464228115883829878_n@4.webp 292w,https://storage.googleapis.com/main-frontpage-images/267/180079652_122700893151171_3464228115883829878_n@6.webp 194w,https://storage.googleapis.com/main-frontpage-images/267/180079652_122700893151171_3464228115883829878_n@8.webp 146w"
        }, {
            "id": 272,
            "src_set": "https://storage.googleapis.com/main-frontpage-images/272/185667251_133352478836744_2309352578422893228_n@1.webp 1169w,https://storage.googleapis.com/main-frontpage-images/272/185667251_133352478836744_2309352578422893228_n@2.webp 584w,https://storage.googleapis.com/main-frontpage-images/272/185667251_133352478836744_2309352578422893228_n@4.webp 292w,https://storage.googleapis.com/main-frontpage-images/272/185667251_133352478836744_2309352578422893228_n@6.webp 194w,https://storage.googleapis.com/main-frontpage-images/272/185667251_133352478836744_2309352578422893228_n@8.webp 146w"
        }, {
            "id": 274,
            "src_set": "https://storage.googleapis.com/main-frontpage-images/274/186564212_494923958506633_8423329208164486258_n@1.webp 1643w,https://storage.googleapis.com/main-frontpage-images/274/186564212_494923958506633_8423329208164486258_n@2.webp 821w,https://storage.googleapis.com/main-frontpage-images/274/186564212_494923958506633_8423329208164486258_n@4.webp 410w,https://storage.googleapis.com/main-frontpage-images/274/186564212_494923958506633_8423329208164486258_n@6.webp 273w,https://storage.googleapis.com/main-frontpage-images/274/186564212_494923958506633_8423329208164486258_n@8.webp 205w"
        }, {
            "id": 309,
            "src_set": "https://storage.googleapis.com/main-frontpage-images/309/188084271_138549391648735_7454561455933481426_n@1.webp 1620w,https://storage.googleapis.com/main-frontpage-images/309/188084271_138549391648735_7454561455933481426_n@2.webp 810w,https://storage.googleapis.com/main-frontpage-images/309/188084271_138549391648735_7454561455933481426_n@4.webp 405w,https://storage.googleapis.com/main-frontpage-images/309/188084271_138549391648735_7454561455933481426_n@6.webp 270w,https://storage.googleapis.com/main-frontpage-images/309/188084271_138549391648735_7454561455933481426_n@8.webp 202w"
        }, {
            "id": 310,
            "src_set": "https://storage.googleapis.com/main-frontpage-images/310/188671542_754293715249162_6803802399565847663_n@1.webp 1495w,https://storage.googleapis.com/main-frontpage-images/310/188671542_754293715249162_6803802399565847663_n@2.webp 747w,https://storage.googleapis.com/main-frontpage-images/310/188671542_754293715249162_6803802399565847663_n@4.webp 373w,https://storage.googleapis.com/main-frontpage-images/310/188671542_754293715249162_6803802399565847663_n@6.webp 249w,https://storage.googleapis.com/main-frontpage-images/310/188671542_754293715249162_6803802399565847663_n@8.webp 186w"
        }, {
            "id": 307,
            "src_set": "https://storage.googleapis.com/main-frontpage-images/307/189372116_483505979639595_3481326266067324430_n@1.webp 1536w,https://storage.googleapis.com/main-frontpage-images/307/189372116_483505979639595_3481326266067324430_n@2.webp 768w,https://storage.googleapis.com/main-frontpage-images/307/189372116_483505979639595_3481326266067324430_n@4.webp 384w,https://storage.googleapis.com/main-frontpage-images/307/189372116_483505979639595_3481326266067324430_n@6.webp 256w,https://storage.googleapis.com/main-frontpage-images/307/189372116_483505979639595_3481326266067324430_n@8.webp 192w"
        }, {
            "id": 315,
            "src_set": "https://storage.googleapis.com/main-frontpage-images/315/IMG_5730@1.webp 2475w,https://storage.googleapis.com/main-frontpage-images/315/IMG_5730@2.webp 1237w,https://storage.googleapis.com/main-frontpage-images/315/IMG_5730@4.webp 618w,https://storage.googleapis.com/main-frontpage-images/315/IMG_5730@6.webp 412w,https://storage.googleapis.com/main-frontpage-images/315/IMG_5730@8.webp 309w"
        }, {
            "id": 269,
            "src_set": "https://storage.googleapis.com/main-frontpage-images/269/179647077_369334104419986_2984316849805885833_n@1.webp 1170w,https://storage.googleapis.com/main-frontpage-images/269/179647077_369334104419986_2984316849805885833_n@2.webp 585w,https://storage.googleapis.com/main-frontpage-images/269/179647077_369334104419986_2984316849805885833_n@4.webp 292w,https://storage.googleapis.com/main-frontpage-images/269/179647077_369334104419986_2984316849805885833_n@6.webp 195w,https://storage.googleapis.com/main-frontpage-images/269/179647077_369334104419986_2984316849805885833_n@8.webp 146w"
        }, {
            "id": 323,
            "src_set": "https://storage.googleapis.com/main-frontpage-images/323/IMG_5753@1.webp 2585w,https://storage.googleapis.com/main-frontpage-images/323/IMG_5753@2.webp 1292w,https://storage.googleapis.com/main-frontpage-images/323/IMG_5753@4.webp 646w,https://storage.googleapis.com/main-frontpage-images/323/IMG_5753@6.webp 430w,https://storage.googleapis.com/main-frontpage-images/323/IMG_5753@8.webp 323w"
        }, {
            "id": 328,
            "src_set": "https://storage.googleapis.com/main-frontpage-images/328/IMG_5756@1.webp 3024w,https://storage.googleapis.com/main-frontpage-images/328/IMG_5756@2.webp 1512w,https://storage.googleapis.com/main-frontpage-images/328/IMG_5756@4.webp 756w,https://storage.googleapis.com/main-frontpage-images/328/IMG_5756@6.webp 504w,https://storage.googleapis.com/main-frontpage-images/328/IMG_5756@8.webp 378w"
        }, {
            "id": 330,
            "src_set": "https://storage.googleapis.com/main-frontpage-images/330/IMG_5774@1.webp 3024w,https://storage.googleapis.com/main-frontpage-images/330/IMG_5774@2.webp 1512w,https://storage.googleapis.com/main-frontpage-images/330/IMG_5774@4.webp 756w,https://storage.googleapis.com/main-frontpage-images/330/IMG_5774@6.webp 504w,https://storage.googleapis.com/main-frontpage-images/330/IMG_5774@8.webp 378w"
        }, {
            "id": 270,
            "src_set": "https://storage.googleapis.com/main-frontpage-images/270/179753374_2204432673021161_8167733403228781342_n@1.webp 1170w,https://storage.googleapis.com/main-frontpage-images/270/179753374_2204432673021161_8167733403228781342_n@2.webp 585w,https://storage.googleapis.com/main-frontpage-images/270/179753374_2204432673021161_8167733403228781342_n@4.webp 292w,https://storage.googleapis.com/main-frontpage-images/270/179753374_2204432673021161_8167733403228781342_n@6.webp 195w,https://storage.googleapis.com/main-frontpage-images/270/179753374_2204432673021161_8167733403228781342_n@8.webp 146w"
        }, {
            "id": 326,
            "src_set": "https://storage.googleapis.com/main-frontpage-images/326/IMG_5775@1.webp 3506w,https://storage.googleapis.com/main-frontpage-images/326/IMG_5775@2.webp 1753w,https://storage.googleapis.com/main-frontpage-images/326/IMG_5775@4.webp 876w,https://storage.googleapis.com/main-frontpage-images/326/IMG_5775@6.webp 584w,https://storage.googleapis.com/main-frontpage-images/326/IMG_5775@8.webp 438w"
        }, {
            "id": 318,
            "src_set": "https://storage.googleapis.com/main-frontpage-images/318/image_50394113@1.webp 2844w,https://storage.googleapis.com/main-frontpage-images/318/image_50394113@2.webp 1422w,https://storage.googleapis.com/main-frontpage-images/318/image_50394113@4.webp 711w,https://storage.googleapis.com/main-frontpage-images/318/image_50394113@6.webp 474w,https://storage.googleapis.com/main-frontpage-images/318/image_50394113@8.webp 355w"
        }, {
            "id": 271,
            "src_set": "https://storage.googleapis.com/main-frontpage-images/271/179830732_491380972224865_5794515033406395163_n@1.webp 1170w,https://storage.googleapis.com/main-frontpage-images/271/179830732_491380972224865_5794515033406395163_n@2.webp 585w,https://storage.googleapis.com/main-frontpage-images/271/179830732_491380972224865_5794515033406395163_n@4.webp 292w,https://storage.googleapis.com/main-frontpage-images/271/179830732_491380972224865_5794515033406395163_n@6.webp 195w,https://storage.googleapis.com/main-frontpage-images/271/179830732_491380972224865_5794515033406395163_n@8.webp 146w"
        }, {
            "id": 321,
            "src_set": "https://storage.googleapis.com/main-frontpage-images/321/image_50448897@1.webp 2523w,https://storage.googleapis.com/main-frontpage-images/321/image_50448897@2.webp 1261w,https://storage.googleapis.com/main-frontpage-images/321/image_50448897@4.webp 630w,https://storage.googleapis.com/main-frontpage-images/321/image_50448897@6.webp 420w,https://storage.googleapis.com/main-frontpage-images/321/image_50448897@8.webp 315w"
        }
    ]

    const [open, setOpen] = useState('')
    return <div className='grid-gallery'>
        <PopUp showPopup={open} closePopup={_ => setOpen('')}>
            <img srcSet={open} sizes={'1500px'} alt='img' className='popup-img'/>
        </PopUp>
        <div className='grid'>
            {withSrcset.map(({id, src_set}, idx) =>
                <div key={id} className='img-wrapper'>
                    <img onClick={_ => setOpen(src_set)} srcSet={src_set} alt={src_set} sizes={
                        '(max-width: 1200px) 250px, 550px'
                    } loading="lazy" />
                </div>
            )}
        </div>
    </div>
}
