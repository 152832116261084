import React, {useRef} from 'react'
import './popup.css'
import cross from '../../assets/icon/clear-24px.svg'

export default function PopUp({children, showPopup, closePopup, className}) {
    const me = useRef(null)
    if (!showPopup) return <div></div>
    return (<div ref={me} className='popup' onClick={e => {
        if (e.target !== me.current) return;
        closePopup();
    }}>
        <img className='close-popup-cross-btn' onClick={closePopup} src={cross} alt='close popup' />
        <div className={`popup-wrapper ${className}`}>
            {children}
        </div>
    </div>)
}
