import React from 'react'
import './image-with-text-section.css'

export default function ImageWithTextSection({src, srcSet, children, className, horizontal=false}) {
    return (<div className={`image-with-text-section ${horizontal ? 'horizontal' : ''} ${className ? className : ''}`}>
        <img
            src={src}
            srcSet={srcSet}
            sizes={'(max-height: 400px) 50px, (max-height: 850px) 100px, 150px'}
            alt='preview of available ceramic and prints from the store'
        />
        <div className='text-side'>
            {children}
        </div>
    </div>)
}
