import React from 'react';
import {Route, Redirect} from 'react-router-dom';

export default function PrivateRoute({children, ...rest}) {
    return <Route {...rest} render={props => {
        const token = localStorage.getItem('token');

        if (!token || token === 'undefined') {
            return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
        }

        return children
    }}/>
}
