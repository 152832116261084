import React from 'react'
import './options.css'

export default function Options({options, onSelected, selected}) {
    return (<div className='options'>
        {options.map(o => <input
            className={selected.indexOf(o) !== -1 ? 'selected' : (selected === '' ? '' : 'unselected')}
            key={o}
            type='button'
            value={o}
            onClick={_ => onSelected(o)}
        />)}
    </div>)
}
