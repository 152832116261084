import React from 'react'
import {Link} from "react-router-dom";
import './backoffice-page.css'

export default function BackofficePage({children}) {
    const url = window.location.href

    const storeLinks = [
        {
            link: '/backoffice/products',
            title: 'Manage'
        }, {
            link: '/backoffice/products/create',
            title: 'Batch create new Products'
        }, {
            link: '/backoffice/products/create_single',
            title: 'Create single product'
        }
    ];
    const mainLinks = [{
        link: '/backoffice/main',
        title: 'Frontpage Images'
    }]
    return (<div className='backoffice-page'>
        <div className='menu'>
            <h1>Pilehøj Backoffice</h1>
            <div className='link-wrapper'>
                <h2>Store</h2>
                {storeLinks.map(link =>
                    <Link key={link.title} className={url.endsWith(link.link) ? 'curr' : ''} to={link.link}>{link.title}</Link>
                )}
                <h2>Main</h2>
                {mainLinks.map(link =>
                    <Link key={link.title} className={url.endsWith(link.link) ? 'curr' : ''} to={link.link}>{link.title}</Link>
                )}
            </div>
        </div>
        <div className='content'>
            {children}
        </div>
    </div>)
}
