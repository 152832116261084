import React from "react";
import LandingPage from "./page/landing/LandingPage";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import BackofficePage from "./back-office/backoffice-page/BackofficePage";
import InventoryPage from "./back-office/inventory/InventoryPage";
import BackofficeProductsPage from "./back-office/products/BackofficeProductsPage";
import PrivateRoute from "./auth/PrivateRoute";
import EditProduct from "./back-office/edit-product/EditProduct";
import WeddingPage from "./page/wedding/WeddingPage";
import WishListPage from "./page/wedding/WishListPage";
import WeddingOverviewPage from "./page/wedding/WeddingOverviewPage";
import LoginPage from "./page/login/LoginPage";
import BackofficeMainPage from "./back-office/main/BackofficeMainPage";
import Logout from "./page/logout/Logout";

function App() {
    return (<Router>
        <Switch>
            <PrivateRoute exact path='/backoffice'>
                <BackofficePage>
                    <InventoryPage />
                </BackofficePage>
            </PrivateRoute>
            <PrivateRoute exact path='/backoffice/products'>
                <BackofficePage>
                    <InventoryPage />
                </BackofficePage>
            </PrivateRoute>
            <PrivateRoute exact path='/backoffice/products/create'>
                <BackofficePage>
                    <BackofficeProductsPage />
                </BackofficePage>
            </PrivateRoute>
            <PrivateRoute exact path='/backoffice/products/create_single'>
                <BackofficePage>
                    <EditProduct />
                </BackofficePage>
            </PrivateRoute>
            <PrivateRoute exact path='/backoffice/main'>
                <BackofficePage>
                    <BackofficeMainPage />
                </BackofficePage>
            </PrivateRoute>

            {/*<Route exact path='/products'>*/}
            {/*    <StorePage />*/}
            {/*</Route>*/}
            {/*<Route exact path='/checkout'>*/}
            {/*    <CheckoutPage />*/}
            {/*</Route>*/}
            {/*<Route exact path='/product/:product_id'>*/}
            {/*    <ProductPage />*/}
            {/*</Route>*/}

            <Route exact path='/bryllup'>
                <WeddingPage />
            </Route>
            <Route exact path='/bryllup/ønsker'>
                <WishListPage />
            </Route>
            <Route exact path='/bryllup/overview'>
                <WeddingOverviewPage />
            </Route>

            <Route exact path='/bryllup/:token'>
                <WeddingPage />
            </Route>

            {/*<Route exact path='/about'>*/}
            {/*    <About />*/}
            {/*</Route>*/}
            <Route exact path='/login'>
                <LoginPage />
            </Route>
            <Route exact path='/logout'>
                <Logout />
            </Route>
            <Route path='/'>
                <LandingPage />
            </Route>
        </Switch>
    </Router>)
}

export default App;
