import React, {useEffect, useState} from 'react'
import {weddingAPI} from "../../api";
import SimpleTable from "../../components/simple-table/SimpleTable";

export default function WeddingOverviewPage() {
    const [overview, setOverview] = useState([])

    useEffect(_ => {
        weddingAPI.getOverview().then(setOverview)
    }, []);

    return (<div className='overview'>
        <SimpleTable sortable headers={[
            'invited_people',
            'user_visited',
            'can_come',
            'drinks_alcohol',
            'food_preference',
            'needs_parking',
            'overnights',
            'sunday_brunch'
        ]} rows={overview} renderRow={o => <tr key={o.id}>
            <td>{o.invited_people}</td>
            <td>{o.user_visited}</td>
            <td>{o.can_come}</td>
            <td>{o.drinks_alcohol}</td>
            <td>{o.food_preference}</td>
            <td>{o.needs_parking}</td>
            <td>{o.overnights}</td>
            <td>{o.sunday_brunch}</td>
        </tr>}/>
    </div>)
}
