import React, {useState, useRef} from 'react'

export default function DragBox({
                                    className,
                                    children,
                                    selectable,
                                    selected,
                                    setSelected,
                                    mouseUpCallback
                                }) {
    const [mouseIsDown, setMouseIsDown] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [dragStart, setDragStart] = useState({x: 0, y: 0});
    const [dragTopLeft, setDragTopLeft] = useState({x: 0, y: 0});
    const [dragSize, setDragSize] = useState({width: 0, height: 0});
    const dragRectRef = useRef(null);
    const parentRef = useRef(null);

    function overlaps(a, b) {
        const x1 = Math.max(a.left, b.left)
        const y1 = Math.max(a.top, b.top)
        const x2 = Math.min(a.right, b.right)
        const y2 = Math.min(a.bottom, b.bottom)
        return x1 < x2 && y1 < y2
    }

    function didStartDragging() {
        if (setSelected) setSelected([]);
    }

    function mouseDown(e) {
        e.preventDefault()
        const dx = e.pageX
        const dy = e.pageY

        setMouseIsDown(true);

        setDragStart({x: dx, y: dy});
        setDragTopLeft({x: dx, y: dy});
        setDragSize({width: 0, height: 0})
    }

    function mouseMove(e) {
        e.preventDefault()
        if (!mouseIsDown) return;
        const dx = e.pageX
        const dy = e.pageY
        const mousePos = {x: dx, y: dy};

        // Since we are using a div, we need the min and max of x and y
        const topLeft = {x: Math.min(dragStart.x, mousePos.x), y: Math.min(dragStart.y, mousePos.y)};
        const botRight = {x: Math.max(dragStart.x, mousePos.x), y: Math.max(dragStart.y, mousePos.y)};

        const width = botRight.x - topLeft.x;
        const height = botRight.y - topLeft.y;

        if (!dragging) {
            if (Math.abs(dragStart.x - mousePos.x) > 3 || Math.abs(dragStart.y - mousePos.y) > 3) {
                setDragging(true);
                didStartDragging();
            } else {
                return;
            }
        }

        setDragTopLeft(topLeft);
        setDragSize({width, height});

        if (!selectable || !selectable.current || !setSelected) return;
        const dragBounding = dragRectRef.current.getBoundingClientRect();
        const s = [];
        Object.keys(selectable.current).forEach(imgName => {
            if (!selectable.current[imgName]) return;
            const imgBounding = selectable.current[imgName].getBoundingClientRect();
            if (overlaps(imgBounding, dragBounding)) {
                s.push(imgName)
            }
        });
        if (s.length !== selected.length) {
            setSelected(s)
        }
    }

    function mouseUp(e) {
        e.preventDefault()
        setDragging(false);
        setMouseIsDown(false);
        setDragSize({width: 0, height: 0});
        if (mouseUpCallback) {
            mouseUpCallback({...dragSize, ...dragTopLeft})
        }
    }

    return (<div className={className} style={{height: '100%', width: '100%'}} ref={parentRef}
                 onMouseDown={mouseDown} onMouseUp={mouseUp} onMouseMove={mouseMove}>
        {children}
        <div className='drag-box' ref={dragRectRef} style={{
            position: 'absolute',
            left: dragTopLeft.x,
            top: dragTopLeft.y,
            width: dragSize.width,
            height: dragSize.height,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            display: dragging ? 'block' : 'none',
            pointerEvents: 'none'
        }}>&nbsp;</div>
    </div>)
}
