import React, {useEffect, useRef, useState, useCallback} from 'react'
import KFileDrop from "../../components/k-file-drop/KFileDrop";
import './backoffice-products.css'
import DragBox from "../../components/drag-box/DragBox";
import {useKeyboardShortcut} from "../../components/UseKeyboardShortcut";
import cross from '../../assets/icon/bx-x.svg'
import KButton from "../../components/k-button/KButton";
import IconWrapper from "../../components/icon-wrapper/IconWrapper";
import EditProduct from "../edit-product/EditProduct";
import {productAPI} from "../../api";
import KTabs from "../../components/k-tabs/KTabs";

export default function BackofficeProductsPage() {
    const [images, setImages] = useState([]);
    const [selectedImageNames, setSelectedImageNames] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);

    const [imageType, setImageType] = useState('Upload');

    const [imageGroups, setImageGroups] = useState([]);
    const [nextProductId, setNextProductId] = useState(0);
    const rowsRef = useRef({});

    const [createProductFromGroup, setCreateProductFromGroup] = useState('');

    useEffect(() => {
        setSelectedImages(images.filter(i => selectedImageNames.indexOf(i.name) !== -1))
    }, [images, selectedImageNames, setSelectedImages]);

    const uploadedImage = useCallback((name, data) => {
        setImages(prev => {
            if (images.some(i => i.name === name)) return prev;
            return ([...prev, {name, data}])
        })
    }, [images, setImages]);

    const selectedImage = useCallback(imgName => {
        setSelectedImageNames(prev => {
            if (prev.indexOf(imgName) === -1) {
                return [...prev, imgName]
            }
            return prev.filter(p => p !== imgName)
        })
    }, [setSelectedImageNames]);

    const addToProduct = useCallback((productId, imgNames) => {
        const productImages = images.filter(i => imgNames.indexOf(i.name) !== -1);
        setImages(prev => prev.filter(p => imgNames.indexOf(p.name) === -1));

        setImageGroups(p => ({...p, [productId]: [...(p[productId] || []), ...productImages]}));
        productImages.forEach(pi => delete rowsRef.current[pi.name])
    }, [setImages, images, setImageGroups]);

    const createNewProduct = useCallback(imgNames => {
        addToProduct(nextProductId, imgNames);
        setNextProductId(productId => productId + 1);
    }, [addToProduct, nextProductId, setNextProductId]);

    const disolveProduct = useCallback(productId => {
        const toAdd = imageGroups[productId]
        toAdd.forEach(t => uploadedImage(t.name, t.data))
        delete imageGroups[productId]
    }, [uploadedImage, imageGroups]);

    const removeImageFromProduct = useCallback((productId, imgName) => {
        if (imageGroups[productId].length <= 1) {
            disolveProduct(productId)
            return;
        }
        setImageGroups(p => ({...p, [productId]: p[productId].filter(p => p.name !== imgName)}))
    }, [imageGroups, disolveProduct]);

    function fetchImagesFromBucket() {
        productAPI.fetchRawImages().then(rawImages => {
            rawImages.forEach(({image_name, signed_url}) => {
                uploadedImage(image_name, signed_url)
            })
        })
    }

    useKeyboardShortcut(['Enter'], () => {
        createNewProduct(selectedImageNames);
        setSelectedImageNames([])
    });
    useKeyboardShortcut(['Escape'], () => {
        setSelectedImageNames([])
    });

    if (createProductFromGroup !== '') {
        return <EditProduct imageType={imageType} images={imageGroups[createProductFromGroup]} onDone={_ => {
            setImageGroups(p => {
                let k = {...p};
                delete k[createProductFromGroup];

                setCreateProductFromGroup('');
                return k
            });
        }}/>
    }

    return (<DragBox className='backoffice-products' selectable={rowsRef} setSelected={setSelectedImageNames}
                     selected={selectedImageNames}>
        <h2>Products</h2>
        <KTabs selected={imageType} setSelected={setImageType} tabTitles={['Upload', 'Bucket']} />

        {imageType === 'Upload' && <KFileDrop asPopup={false} didSelectFile={uploadedImage} displayFiles={false}/>}
        {imageType === 'Bucket' && <KButton onClick={fetchImagesFromBucket} value='Fetch from bucket' />}

        <div className='main-content'>
            <div className='ungrouped-images'>
                {images.map(i => <img
                    onClick={_ => selectedImage(i.name)}
                    className={`product-image ${selectedImageNames.indexOf(i.name) !== -1 ? 'selected' : ''}`}
                    ref={el => rowsRef.current[i.name] = el}
                    key={i.name}
                    src={i.data}
                    alt={i.name}
                />)}
            </div>
            <div>
                {Object.keys(imageGroups).map(productId => {
                    const productImages = imageGroups[productId];
                    return <div className='product' key={productId}>
                        <IconWrapper icon={cross} callback={_ => disolveProduct(productId)}
                                     classname='disolve-product'/>
                        <div className='controls'>
                            <KButton value='Add selected' onClick={_ => addToProduct(productId, selectedImageNames)}/>
                        </div>
                        <div className='product-images'>
                            {productImages.map(({name, data}) => <div className='product-image-controls' key={name}>
                                <IconWrapper icon={cross} classname='remove-img' callback={_ => {
                                    removeImageFromProduct(productId, name)
                                }}/>
                                <img className='product-image' src={data} alt={name} key={name}/>
                            </div>)}
                        </div>
                        <KButton value='Create products from groups' big={true}
                                 onClick={_ => setCreateProductFromGroup(productId)}/>
                    </div>
                })}
            </div>
        </div>
        <div className='selected-preview'>
            {selectedImages.map(i => <img
                onClick={_ => selectedImage(i.name)}
                className='product-image small'
                key={i.name}
                src={i.data}
                alt={i.name}
            />)}
        </div>
    </DragBox>)
}
