import React from 'react'
import blomsterkrans_02 from "../../../assets/wedding/blomsterkrans_02.png";
import './../wedding.css'
import './../wish-list.css'

export default function WishList() {
    return (
        <section className='section-with-image wish-list'>
            <img className='' src={blomsterkrans_02} alt='blomster krans'/>
            <h1>Ønskeliste</h1>
            <ol>
                <li>Tilskud til bryllupsrejse <i>(Vi vil køre Frankrig rundt i "Skuda' Fabialous" - vi modtager også
                    idéer, restaurantforslag eller lignende med kyshånd)</i></li>
                <li>Pænt knivsæt <i>(gerne med træskaft)</i></li>
                <li>Grillpande <i>(til induktion)</i></li>
                <li>Steak knive</li>
                <li>Mad oplevelser</li>
                <li>Spa ophold</li>
                <li>Roser til haven</li>
                <li>Silke pudebetræk fra Cillouettes</li>
                <li>Citrontræ gerne +1.6m</li>
                <li>Magnolia Træ i pink</li>
                <li>Store lamme/fåreskind</li>
                <li>Foodprocessor</li>
            </ol>
        </section>)
}
