import React from 'react'
import './k-button.css'

export default function KButton({className, value, onClick, hidden = false, disabled=false, loading=false, big=false}) {
    return <input
        hidden={hidden}
        type='button'
        className={`k-button ${className} ${disabled ? 'disabled' : ''} ${loading && 'loading'} ${big ? 'big' : ''}`}
        disabled={disabled}
        value={loading ? 'Loading...' : value}
        onClick={loading ? () => {} : onClick} />
}
