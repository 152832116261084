import React from 'react'
import shoes from "../../assets/wedding/shoe_icon.png";
import './wedding.css'
import './wish-list.css'

export default function DressCode() {
    return (
        <section className='section-with-image dresscode'>
            <img className='' src={shoes} alt='sko'/>
            <h1>Dresscode</h1>
            <p>
                Vi elsker fancy tøj.
            </p>
            <p>
                Desto festligere jo bedre - så hold jer ikke tilbage med farver, smykker, hatte og flotte
                dansesko. Husk at blokhæle er at foretrække på græsplænen.
            </p>
            <p>
                Dog skal mændende vide at gommen tager jakkesæt på, så ingen smoking i denne omgang.
            </p>
            <p>
                Men hvis det nu bliver 32+ grader - så er det nok også meget godt at have
                et par tynde bukser eller pæne shorts med.
            </p>
            <p>
                Vi tænker at så længe man sidder ned, er bukser ikke påkrævet - ligesom
                når man arbejder hjemmefra.
            </p>

        </section>)
}
