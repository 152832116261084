import React, {useCallback, useEffect, useRef, useState} from 'react';
import KInput from "../../components/k-input/KInput";
import KButton from "../../components/k-button/KButton";
import './edit-product.css'
import ReactCrop from 'react-image-crop'
import {productAPI} from "../../api";
import KFileDrop from "../../components/k-file-drop/KFileDrop";

export default function EditProduct({initialImages, onDone, imageType}) {
    const [images, setImages] = useState([]);

    const [selectedImage, setSelectedImage] = useState({});
    const [displayOffset, setDisplayOffset] = useState({aspect: 7/10});
    const [price, setPrice] = useState('');
    const [artist, setArtist] = useState('Bjørn');
    const [name, setName] = useState('');
    const [isUnique, setIsUnique] = useState(true);
    const [height, setHeight] = useState('');
    const [width, setWidth] = useState('');
    const [description, setDescription] = useState('');

    const [crops, setCrops] = useState({});

    const currentImgRef = useRef(null);

    useEffect(() => {
        if (!initialImages) return;
        setImages(initialImages);
    }, [initialImages]);

    function getCroppedImg(image, crop) {
        if (!image) {
            console.log('No image')
            return
        }
        crop = {...crop}
        crop = {
            width: Math.ceil((crop.width / 100) * image.width),
            height: Math.ceil((crop.height / 100) * image.height),
            x: Math.ceil((crop.x / 100) * image.width),
            y: Math.ceil((crop.y / 100) * image.height),
            unit: 'px',
        }
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        // New lines to be added
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        reject('No blob')
                        return;
                    }
                    blob.name = 'fileName.jpg';
                    resolve(blob);
                },
                "image/jpeg",
                1
            );
        });
    }

    const stoppedDragging = useCallback((_, d) => {
        setDisplayOffset(d)
        setCrops(prev => ({
            ...prev, [selectedImage.name]: {
                crop: d
            }
        }))
    }, [selectedImage, setCrops, setDisplayOffset]);

    const doCrop = useCallback( _ => {
        getCroppedImg(currentImgRef.current, crops[selectedImage.name].crop).then(cropped => {
            console.log(cropped)
            let objectURL = URL.createObjectURL(cropped);
            setCrops(prev => ({
                ...prev, [selectedImage.name]: {
                    ...prev[selectedImage.name],
                    croppedImg: objectURL,
                    croppedData: cropped
                }
            }))
        })
    }, [crops, selectedImage]);

    useEffect(() => {
        if (images.length <= 0) return;
        setSelectedImage(images[0])
    }, [images, setSelectedImage]);

    const removeImg = useCallback(name => {
        setImages(prev => prev.filter(p => p.name !== name));
        if (selectedImage.name === name) setSelectedImage({});
    }, [setImages, selectedImage, setSelectedImage])

    function previewCropped(name, data) {
        let display = [<img className='img-preview'
            src={data}
            key={name}
            onClick={_ => setSelectedImage({name, data})}
        />];
        if (crops[name]) {
            if (crops[name].croppedImg) {
                display = <img className='img-preview' src={crops[name].croppedImg} alt='cropped'/>
            } else {
                display.push(<div style={{
                    position: 'absolute',
                    left: `${crops[name].crop.x}%`,
                    top: `${crops[name].crop.y}%`,
                    width: `${crops[name].crop.width}%`,
                    height: `${crops[name].crop.height}%`,
                    display: crops[name].crop.width > 0 ? 'block' : 'none',
                    pointerEvents: 'none',
                    boxShadow: '0 0 0 100vmax rgba(0, 0, 0, 0.1)'
                }}>&nbsp;</div>)
            }
        }
        return <div
            className='preview-wrapper'>
            <div className='small-img-wrapper'>
                {display}
            </div>
            <div className='small-img-buttons'>
                <KButton value='remove' onClick={_ => removeImg(name)} />
            </div>
        </div>
    }

    const createProduct = useCallback(_ => {
        productAPI.createProduct(name, artist, price, isUnique, height, width, description).then(productId => {
            Promise.all(images.map(async (i, idx) => {
                if (imageType === 'Bucket') {
                    return productAPI.addImageToProduct(productId, null, idx, i.name)
                }
                console.log(crops[i.name])
                if (!crops[i.name] || !crops[i.name].croppedData) {
                    return productAPI.addImageToProduct(productId, i.data, idx)
                }
                const cropped = crops[i.name].croppedData;
                console.log('Using cropped');
                return new Promise(resolve => {
                    let reader = new FileReader();
                    reader.readAsDataURL(cropped);
                    reader.onloadend = function () {
                        console.log('Done loading.')
                        let base64String = reader.result;
                        productAPI.addImageToProduct(productId, base64String, idx).then(_ => {
                            resolve()
                        })
                    }
                })
            })).then(_ => {
                onDone();
                console.log('DONE creating product!')
            })
        })
    }, [imageType, onDone, crops, images, name, artist, price, isUnique, description, height, width]);

    return (<div className='edit-product'>
        <div className='image-editor-wrapper'>
            <div className='selected-image'>
                <ReactCrop
                    onImageLoaded={i => currentImgRef.current = i}
                    className='react-cropper'
                    crop={displayOffset} onChange={stoppedDragging} src={selectedImage.data}>
                    <div className='not-me' style={{
                        position: 'absolute',
                        left: `${displayOffset.x}%`,
                        top: `${displayOffset.y}%`,
                        width: `${displayOffset.width}%`,
                        height: `${displayOffset.height}%`,
                        display: displayOffset.width > 0 ? 'block' : 'none',
                        boxShadow: '0 0 0 100vmax rgba(0,0,0,0.1)'
                    }} onClick={console.log}>&nbsp;
                    </div>
                </ReactCrop>
                <KButton value='crop' onClick={doCrop} big hidden={!crops[selectedImage.name] || crops[selectedImage.name].crop.width === 0} />
            </div>
            <div className='all-images-wrapper'>
                {images.map(i => previewCropped(i.name, i.data))}
                <div className='small-img-wrapper new-img'>
                    <KFileDrop asPopup={false} displayFiles={false} text='New image'
                               didSelectFile={(name, data) => {
                                   setImages(p => [...p, {name, data}]);
                                   return true
                               }} />
                </div>
            </div>
        </div>
        <div className='controls'>
            <KInput
                title='Price'
                value={price}
                onChanged={setPrice}/>
            <KInput
                title='Artist' options={['Bjørn', 'Linea', 'Lis']}
                value={artist}
                onChanged={setArtist}/>
            <KInput
                title='Name (optional)'
                value={name}
                onChanged={setName}/>
            <KInput
                title='Unique' type='checkbox'
                value={isUnique}
                onChanged={setIsUnique}/>
            <KInput
                title='Height' type='number'
                value={height}
                onChanged={setHeight}/>
            <KInput
                title='Width' type='number'
                value={width}
                onChanged={setWidth}/>
            <KInput
                title='Description' type='textbox'
                value={description}
                onChanged={setDescription}/>

            <KButton big value='Submit' onClick={createProduct} />
        </div>
    </div>)
}
