import React, {useState} from 'react'
import './wedding.css'
import './wish-list.css'
import {Redirect} from "react-router-dom";
import WishList from "./wish-list/WishList";

export default function WishListPage() {
    const [redir, setRedir] = useState('');

    if (redir !== '') {
        return <Redirect to={redir}/>
    }

    return (<div className='wedding-page wish-list'>
        <div className='text-area'>
            <WishList/>
        </div>
    </div>)
}
