import {useCallback, useEffect, useReducer} from "react";

const blacklistedTargets = ['input', 'text', 'textArea']
const keysReducer = (state, action) => {
    switch (action.type) {
        case "set-key-down":
            return { ...state, [action.key]: true };
        case "set-key-up":
            return { ...state, [action.key]: false };
        default:
            return state;
    }
};

export const useEventShortcut = (eventName, callback) => {
    if (!callback || typeof callback !== 'function') throw new Error('Must supply a function as callback')

    const eventCallback = useCallback(event => {
        const {type, target} = event;
        if (blacklistedTargets.includes(target.tagName)) return;
        if (type !== eventName) return;
        callback(event)
    }, [callback, eventName])

    useEffect(() => {
        window.addEventListener(eventName, eventCallback);
        return () => window.removeEventListener(eventName, eventCallback)
    }, [callback, eventCallback, eventName])
}

export const useKeyboardShortcut = (shortcutKeys, keyUpCallback) => {
    if (!Array.isArray(shortcutKeys)) throw new Error('Shortcut keys must be an array')
    if (!shortcutKeys.length) throw new Error('Must supply atleast one shortcut key')

    const initialKeyMapping = shortcutKeys.reduce((currentKeys, key) => {
        currentKeys[key] = false;
        return currentKeys
    }, {});

    const [keys, setKeys] = useReducer(keysReducer, initialKeyMapping)

    const keydownListener = useCallback(keydownEvent => {
        const {key, target, repeat} = keydownEvent;
        if (repeat) return;
        if (blacklistedTargets.includes(target.tagName)) return;
        if (!shortcutKeys.includes(key)) return;
        keydownEvent.preventDefault()
        if (!keys[key]) {
            setKeys({type: 'set-key-down', key})
        }
    }, [shortcutKeys, keys]);

    const keyupListener = useCallback(keyupEvent => {
        keyupEvent.preventDefault();
        keyupEvent.stopPropagation();
        const {key, target} = keyupEvent;
        if (blacklistedTargets.includes(target.tagName)) return;
        if (!shortcutKeys.includes(key)) return;
        if (keys[key]) {
            keyupEvent.preventDefault()
            setKeys({type: "set-key-up", key})
            if (keyUpCallback) {
                keyUpCallback(key)
            }
        }
    }, [keyUpCallback, shortcutKeys, keys])

    useEffect(() => {
        window.addEventListener("keydown", keydownListener, true);
        return () => window.removeEventListener("keydown", keydownListener, true);
    }, [keydownListener])

    useEffect(() => {
        window.addEventListener("keyup", keyupListener, true);
        return () => window.removeEventListener("keyup", keyupListener, true)
    }, [keyupListener])
}

