import React, {useCallback, useEffect, useState} from 'react'
import KFileDrop from "../../components/k-file-drop/KFileDrop";
import {frontpageImageAPI} from "../../api";
import './backoffice-main.css';
import PopUp from "../../components/popup-window/PopUp";


export default function BackofficeMainPage(props) {
    const [images, setImages] = useState([]);
    const [existingImages, setExistingImages] = useState([]);

    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        frontpageImageAPI.listImages().then(setExistingImages)
    }, []);

    const uploadedImage = useCallback((name, data) => {
        frontpageImageAPI.createImage(name, '', data).then(newFrontpageImg =>
            setImages(prev => {
                if (images.some(i => i.name === name)) return prev;
                return ([...prev, {name, data}])
            })
        )
    }, [images, setImages]);

    console.log(existingImages)

    return (<div className={'backoffice-main-page'}>
        <PopUp className='selected-popup' showPopup={selectedImage !== null} closePopup={_ => setSelectedImage(null)}>
            {selectedImage && <>
                <p>Original</p>
                <img className='primary' src={selectedImage?.signed_url} alt={selectedImage?.alt_text}/>
                <div className='copies'>
                    {existingImages.filter(i => i.original_image_id === selectedImage.id).map(i =>
                        <>
                            <label>Scale factor: {i.size}</label>
                            <img key={i.id} src={i.signed_url} alt={i.alt_text}/>
                            <br/>
                        </>
                    )}
                </div>
            </>}
        </PopUp>
        <KFileDrop asPopup={false} didSelectFile={uploadedImage} displayFiles={true} isImages/>
        <div className={'existing-images'}>
            {existingImages.filter(i => i.original_image_id === null).map(i =>
                <img onClick={() => setSelectedImage(i)} key={i.id} src={i.signed_url} alt={i.alt_text}/>
            )}
        </div>
    </div>)
}
