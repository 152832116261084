import React, {useState, useEffect} from 'react'
import cartIcon from '../../assets/icon/cart_icon.png'
import './cart.css'
import {productAPI} from "../../api";
import IconWrapper from "../icon-wrapper/IconWrapper";
import KButton from "../k-button/KButton";
import {Redirect} from "react-router-dom";

export default function Cart() {
    const [isOpen, setIsOpen] = useState(false);
    const [products, setProducts] = useState([]);

    const [redir, setRedir] = useState('');

    useEffect(() => {
        productAPI.listAllProducts().then(setProducts)
    }, []);

    if (redir !== '') {
        return <Redirect to={redir} />
    }

    return ([
        <div className='items' key='cart-items' hidden={!isOpen}>
            {products.map(p =>
                [<div key={p.id} className='item'>
                    <div className='img-wrap'>
                        <img src={p.images[0].url} alt='prod-img'/>
                    </div>
                    <div>
                        <p>{p.product_name}</p>
                        <p>{p.price},- DKK</p>
                    </div>
                </div>,
                    <hr key='line'/>]
            )}
            <KButton value='CHECKOUT' key='checkout-btn' big onClick={_ => setRedir('/checkout')}/>
        </div>,
        <div className='cart' key='cart-icon' onClick={_ => setIsOpen(p => !p)}>
            <IconWrapper icon={cartIcon} classname='cart-icon' />
        </div>
    ])
}
