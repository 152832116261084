import React, {useState, useCallback} from 'react'
import './login.css'
import KInput from "../../components/k-input/KInput";
import KButton from "../../components/k-button/KButton";
import {Redirect} from "react-router-dom";
import logo from '../../assets/galleriPilehøj_Visitkort.86bc9852.jpg'
import {userAPI} from "../../api";
import {useKeyboardShortcut} from "../../components/UseKeyboardShortcut";

export default function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [redirectTo, setRedirectTo] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState({});

    const doLogin = useCallback(() => {
        setLoading(true);
        userAPI.login(username, password)
            .then(({token, expires}) => {
                localStorage.setItem('token', token);
                localStorage.setItem('tokenExpires', expires);
                setLoading(false);
                setRedirectTo({from: {pathname: "/"}})
            }).catch(e => {
            console.log(e);
            setLoading(false);
            setErrors({password: 'Wrong username or password'})
        })
    }, [setRedirectTo, username, password])

    useKeyboardShortcut(['Enter'], doLogin);

    if (redirectTo) {
        return <Redirect to={redirectTo}/>
    }
    return (<div className='login-page'>
        <div className='login-wrapper'>
            <img src={logo} alt='logo' />
            <KInput
                title='Email'
                onChanged={setUsername}
                value={username}
                error={errors['username']}/>
            <KInput
                title='Password'
                type='password'
                onChanged={setPassword}
                value={password}
                error={errors['password']} />
            <KButton loading={loading} className='login-btn' value='Login' onClick={doLogin} />
        </div>
    </div>)
}
