import React, {useState} from "react";
import './k-input.css'

export default function KInput(
    {
        title,
        type,
        options,
        placeholder,
        onChanged,
        value,
        style,
        className,
        onlySelectableOptions,
        multiSelect,
        error,
        disabled
    }) {
    const [focused, setFocused] = useState(false);
    const [searching, setSearching] = useState('')

    function renderSelectedList() {
        if (!multiSelect || !value) return;
        return (<div className={`selected-wrapper`}>
            {value
                .filter((o, idx) => value.indexOf(o) === idx)
                .map(o => <span className='selected-item' key={o} onClick={() => proxyOnChanged(o, true)}>{o}</span>)}
        </div>)
    }

    function proxyOnChanged(newValue, clicked) {
        if (multiSelect) {
            if (!clicked) return;
            if (value.indexOf(newValue) === -1) {
                onChanged([...value, newValue])
            } else {
                onChanged(value.filter(v => v !== newValue))
            }
        } else {
            if (onlySelectableOptions) {
                if (clicked) {
                    onChanged(newValue)
                    setSearching('')
                } else {
                    setSearching(newValue)
                }
            } else {
                onChanged(newValue)
            }
        }
    }

    return <div className={`k-input ${className ? className : ''}`}
                onFocus={_ => setFocused(true)}
                onBlur={_ => setFocused(false)}
                style={style}>
        <div className='input-wrapper'>
            <label>{title}</label>
            <input type={type}
                   className={(onlySelectableOptions || multiSelect) ? 'non-selectable' : ''}
                   value={(onlySelectableOptions && !value) ? searching : value}
                   placeholder={placeholder}
                   disabled={disabled}
                   checked={type === 'checkbox' && value}
                   onChange={e => proxyOnChanged((type === 'checkbox' ? e.target.checked : e.target.value), false)}
            />
            {error && <label className='error'>{error}</label>}
        </div>
        {options && focused && <div className='option-wrapper'>
            {options
                .filter((o, idx) => options.indexOf(o) === idx)
                .filter(o => !onlySelectableOptions || o.toLowerCase().startsWith(searching.toLowerCase()))
                .map(o => <li key={o} onMouseDown={_ => proxyOnChanged(o, true)} className='drop-option'>{o}</li>)}
        </div>}
        {renderSelectedList()}
    </div>
}
