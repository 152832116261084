import React, {useState, useMemo} from 'react'
import './simple-table.css'
import KButton from "../k-button/KButton";

export default function SimpleTable({headers, rows, renderRow, extraRow, className, specialOrders, sortable = false}) {
    const [orderBy, setOrderBy] = useState('');
    const [desc, setDesc] = useState(false);

    const orderByFunction = useMemo(() => {
        if (specialOrders && specialOrders.length > 0 && orderBy in specialOrders) {
            return specialOrders[orderBy]
        }
        return d => d[orderBy]
    }, [orderBy, specialOrders])

    function sorter(a, b) {
        if (!sortable) return 1
        if (orderBy === '') return 0
        const ra = orderByFunction(a)
        const rb = orderByFunction(b)

        if (ra === null && rb === null) {
            return 0
        }
        if (ra === null) {
            return desc ? -1 : 1
        }
        if (rb === null) {
            return desc ? 1 : -1
        }

        if (desc) {
            if (rb.localeCompare) {
                return rb.localeCompare(ra.toString())
            }
            return rb - ra
        } else {
            if (ra.localeCompare) {
                return ra.localeCompare(rb.toString())
            }
            return ra - rb
        }
    }

    function renderDeleteBtn(onClicked) {
        return <td className='delete-btn'><KButton tabIndex={-1} className='delete-btn' value='delete'
                                                     onClick={onClicked}/></td>
    }

    return (<table className={`${className} simple-table`}>
        <thead>
        <tr>
            {headers.map(header =>
                <th onClick={_ => {
                    if (orderBy === header) {
                        setDesc(p => !p);
                    } else {
                        setDesc(false);
                        setOrderBy(header);
                    }
                }}
                    className={`${sortable ? 'clickable' : ''} ${orderBy === header ? 'order-by' : ''}`}
                    key={header}>
                    {header}
                </th>)}
        </tr>
        </thead>
        <tbody>
        {rows.sort(sorter).map(r => renderRow(r, renderDeleteBtn))}
        {extraRow}
        </tbody>
    </table>)
}
