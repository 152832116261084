import React, {useState, useEffect} from 'react'
import {userAPI} from "../../api";
import {Redirect} from "react-router-dom";

export default function Logout() {
    const [redirectTo, setRedirectTo] = useState(null);
    useEffect(() => {
        userAPI.logout()
        setRedirectTo('/login')
    }, []);


    if (redirectTo) {
        return <Redirect to={redirectTo}/>
    }

    return <p>Logging out...</p>
}
