import React, {useCallback, useState, useEffect} from 'react'
import {productAPI} from "../../api";
import './inventory-page.css'
import KButton from "../../components/k-button/KButton";

export default function InventoryPage() {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        productAPI.listAllProducts().then(setProducts)
    }, []);

    const deleteClicked = useCallback(product => {
        const del = window.confirm('Delete product: ' + product.product_name + '?')
        if (del) {
            productAPI.deleteProduct(product.id).then(_ => {
                console.log('Deleted product!');
                setProducts(prev => {
                    return prev.filter(p => p.id !== product.id)
                })
            })
        }
    }, []);

    return (<div className='product-management'>
        <h2>Inventory</h2>
        <div className='product-wrapper'>
            {products.map(p =>
                <div key={p.id} className='product'>
                    <div className='img-wrapper'>
                        <img src={p.images[0].url} alt='product-name'/>
                    </div>
                    <div className='price-wrapper'>
                        <p>{p.product_name}</p>
                        <p>{p.price},-</p>
                    </div>
                    <div className='grow'>&nbsp;</div>
                    <div className='button-wrapper'>
                        <KButton className='delete-btn' value='delete' onClick={_ => deleteClicked(p)} />
                        <KButton className='save-btn' value='save'  />
                    </div>
                </div>
            )}
        </div>
    </div>)
}
