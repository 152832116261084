import React, {useState} from 'react'
import Header from "../../components/header/header";
import Map from "../../components/map/map";
import ImageWithTextSection from "../../components/img-with-text-section/ImageWithTextSection";
import GridGallery from "../../components/grid-gallery/grid-gallery";
import Footer from "../../components/footer/footer";
import './landing-page.css'
import winterLandscape from '../../assets/IMG_2262.jpeg';

export default function LandingPage() {
    const [open, setOpen] = useState(new Date() < new Date(2021, 7, 15, 17, 0))
    return (<div className="App">
        {/*<PopUp showPopup={open} closePopup={_ => setOpen(false)}>*/}
        {/*    <h2>Closed today Sunday the 15. August</h2>*/}
        {/*    <p>Thanks for your patience. We hope to see you wednesday when we open again!</p>*/}
        {/*</PopUp>*/}
        <Header/>

        <img src='https://storage.googleapis.com/main-frontpage-images/GuleBlomster_40x40.jpg' alt='winter landscape' className='wide-splash'/>

        {/*<SimpleImageCollection/>*/}
        <ImageWithTextSection
            className='yellow-area'
            horizontal
            src={'https://storage.googleapis.com/pilehoj-img/galleripilehoj_yellowarea.jpg'}>
            <div>
                <h2>2024 is a great year for galleries!</h2>
                <p>
                    Our gallery is an expression of a long wish to open our own place. We have been working on this project
                    for a long time and we are still very excited to show our work to the world.
                </p>
                <p>
                    This will be our third year open and we are looking forward to many more. We hope to see you soon!
                </p>
            </div>

            <div>
                <h2>Bornholms Kunstrunde</h2>
                <p>
                    We are participating in Bornholms Kunstrunde as always.
                </p>
                <p>
                    Bornholms Kunstrunde is a yearly event where galleries and artists open their doors to the public.
                </p>
                <p>And even more exciting - Linea and Bjørn are now both on the Board of Directors for kunstrunden.</p>
                <a href={'https://bornholmskunstrunde.dk/'}>Read more: Bornholms Kunstrunde</a>
            </div>
        </ImageWithTextSection>
        <section className='intro-text'>
            <h1>Østermarie, Bornholm</h1>
            <p>
                Welcome to our gallery! We're located in the beautiful village of Østermarie on the island of Bornholm,
            </p>
            <p>
                In our gallery you will find unique ceramics and limited print photographs.
            </p>
        </section>
        <Map/>
        <GridGallery/>
        <Footer/>
    </div>)
}
