import React, {useState, useEffect, useCallback, useMemo} from 'react'
import './wedding.css'
import blomsterBund from '../../assets/wedding/blomster_bund.png'
import Options from "../../components/option/Options";
import weddingLogo from '../../assets/wedding/B&L icon.jpg';
import twig_01 from '../../assets/wedding/twig_01.png';
import twig_02 from '../../assets/wedding/twig_02.png';
import rnd_03 from '../../assets/wedding/rnd_03.png';
import twig_03 from '../../assets/wedding/twig_03.png';
import twig_04 from '../../assets/wedding/twig_04.png';

import phone from '../../assets/icon/bxs-phone.svg';
import mail from '../../assets/icon/bx-mail-send.svg';
import valmue from '../../assets/wedding/valmue.png';
import {useParams} from "react-router-dom";
import {weddingAPI} from "../../api";

import debounce from 'lodash.debounce';
import WishList from "./wish-list/WishList";
import DressCode from "./DressCode";

export default function WeddingPage() {
    const [timeUntil, setTimeUntil] = useState('');
    const [invitation, setInvitation] = useState({});

    const [existingRsvp, setExistingRsvp] = useState([]);

    let {token} = useParams();
    const [answers, setAnswers] = useState({
        can_come: '',
        overnights: '',
        overnights_days: '',
        needs_parking: '',
        food_preference: '',
        drinks_alcohol: [],
        sunday_brunch: '',
    });

    useEffect(() => {
        const interval = setInterval(() => {
            const date = new Date(2022, 7, 6, 14)
            const diff = (date - Date.now()) / 1000

            setTimeUntil({
                days: Math.floor(diff / 86400).toString().padStart(2, '0'),
                hours: Math.floor((diff % 86400) / 3600).toString().padStart(2, '0'),
                minutes: Math.floor((diff % 3600) / 60).toString().padStart(2, '0'),
                seconds: Math.floor(diff % 60).toString().padStart(2, '0'),
            })
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const getRsvp = useCallback((invitation_id) => {
        weddingAPI.getRsvp(invitation_id).then(setExistingRsvp)
    }, []);

    const createRsvp = useCallback((inv_id, question_id, value) => {
        weddingAPI.createRsvp(inv_id, question_id, value).then(_ => {})
    }, []);

    useEffect(() => {
        weddingAPI.getInvitationFromToken(token).then(i => {
            setInvitation(i)
            getRsvp(i.id)
            createRsvp(i.id, 'user_visited', Date.now().toString())
        })
    }, [token, createRsvp]);

    const debouncedEventHandler = useMemo(() => debounce(createRsvp, 300), []);

    const optionSelected = useCallback((question_id, value) => {
        setAnswers(prev => ({...prev, [question_id]: value}))

        if (typeof value === 'object') {
            debouncedEventHandler(invitation.id, question_id, value.join(','))
        } else {
            debouncedEventHandler(invitation.id, question_id, value)
        }
    }, [setAnswers, invitation]);

    const renderQuestion = useCallback((q_id, question, answer, type, overrideOptions = null, subtitle = null) => {
        if (!invitation.invited_people) return;
        const du_i = invitation.invited_people.length > 1 ? 'i' : 'du'

        question = question.replace(/du/g, du_i)

        return <div className='input-field'>
            <label>{question}</label>
            {subtitle !== null && <h5>{subtitle}</h5>}
            {type === 'binary' &&
                <Options
                    options={overrideOptions === null ? ['Ja', 'Nej'] : overrideOptions}
                    selected={answer}
                    onSelected={v => optionSelected(q_id, v)}
                />}
            {type === 'text' &&
                <input type='text' value={answer} onChange={e => optionSelected(q_id, e.target.value)}/>}
            {type === 'amount' && <input
                type='number'
                value={answer}
                onChange={e => optionSelected(q_id, e.target.value)}
            />}
            {type === 'people' && <Options
                options={invitation.invited_people}
                selected={answer}
                onSelected={v => optionSelected(q_id, answer.indexOf(v) === -1 ? [...answer, v] : answer.filter(a => a !== v))}
            />}
            <hr/>
        </div>
    }, [invitation]);

    return (<div className='wedding-page'>
            <div className='text-area'>
                <img className='logo title-img' src={weddingLogo} alt='blomster_pynte_billede'/>
                <section>
                    <h1>Linea & Bjørns bryllup</h1>
                    <div className='horizontal'>
                        <h2 className='address'>Savmøllevej 10, Østermarie</h2>
                        <h2 className='date'>Lørdag, 6. August, 2022</h2>
                        <h2 className='time-until'>{timeUntil.days} dage {timeUntil.hours} timer {timeUntil.minutes} minut{timeUntil.minutes === '01' ? '' : 'er'} {timeUntil.seconds} sek</h2>
                    </div>
                    {<h2>Kære {invitation.pretty_invited || 'Gæst'}</h2>}
                    <p>Tre år kan betyde vidt forskellige ting for forskellige mennesker. Det kan være tre år, på et
                        kedeligt job - tilbage i 80’erne.
                        En bachelor. En hjemmestrikket sweater. Eller de bedste 3 år af ens liv.
                        De tre år kan være grund nok til at satse alt på en person.
                    </p>
                    <p>
                        Det sidste har været sandt for os de seneste tre år.
                        Vi håber derfor at du vil være med til at fejre os og kærligheden til sommer.
                    </p>
                    <p>
                        Hvis du er inviteret er det fordi du er en ven. En ven af os og en som vi gerne vil se mere til.
                    </p>
                    <p>
                        Det bliver på solskinsøen - vi har hørt rygter om at alle de ekstra 80 timer kommer den dag vi
                        holder bryllup.
                    </p>
                    <p>
                        Vi elsker dig. Vi elsker hinanden. Lad os holde en fest og fejre al den kærlighed.
                    </p>
                </section>
                <DressCode />
                <WishList />
                <section>
                    <div className='title-wrapper'>
                        <img className='title-img twig_left' src={twig_02} alt='flower img'/>
                        <h1>Camping</h1>
                        <img className='title-img twig_right' src={twig_01} alt='flower img'/>
                    </div>
                    <p>Til bryllupet vil Pilehøj huse et fantastisk camping område hvor man kan opsætte sit eget
                        telt.</p>
                    <p>
                        Man kan selvfølgelig også finde sig et værelse eller et hus i nærheden hvor man kan overnatte.
                    </p>
                    <p>
                        Campingpladsen vil dog være et bryllup værdigt:
                    </p>
                    <ol>
                        <li>Indendørs toiletter.</li>
                        <li>Plads til opbevaring af pænt tøj</li>
                        <li>Plads indendørs til at klæde sig på og gøre sig klar</li>
                        <li>Bordplads med spejl til at sætte hår og makeup</li>
                        <li>Refreshment telt med kaffe og panodiller til dagen derpå</li>
                    </ol>

                    <h3 className='camping-quote'>
                        Vi tror camping området bliver et helt specielt sted at overnatte. Under de bornholmske
                        stjerner i stille omgivelser. Vågn op til fuglekvidder, kaffe og morgenmad i det fri.
                    </h3>
                </section>
                <section className='section-with-image coming-friday'>
                    <h1>Hvis du kommer fredag</h1>
                    <div>
                        <img className='title-img' src={twig_03} alt='pynte blomst'/>
                        <img className='title-img' src={twig_03} alt='pynte blomst'/>
                    </div>
                    <p>Du er velkommen til at ankomme fredag, hvis du er frisk til at hjælpe til med lidt småting.</p>
                    <p>
                        Vi forventer at være færdige med alle de kritiske ting allerede torsdag.
                    </p>
                    <br/>
                    <h3><b>For folk som overnatter fredag til lørdag</b></h3>
                    <p>Der er morgenmad lørdag morgen kl 09:00 og frokost kl 12:00.</p>
                </section>
                <section className='section-with-image time-section'>
                    <img className='title-img' src={rnd_03} alt='pynte blomst'/>
                    <h2>Fredag</h2>
                    <div className='timeline friday'>
                        <b>12:00</b>
                        <p>Hegnet vælter (camping åbner)</p>
                    </div>
                    <h2>Lørdag</h2>
                    <div className='timeline'>
                        <b>15:00</b>
                        <p> Vielse</p>
                        <span>|</span>
                        <b>18:30</b>
                        <p> Middag</p>
                        <span>|</span>
                        <b>23:00</b>
                        <p> Brudevals</p>
                        <span>|</span>
                        <b>23:15</b>
                        <p> Bryllupskage</p>
                        <span>|</span>
                        <b>23:45</b>
                        <p> Dansegulvet åbnes {'<3'}</p>
                    </div>
                    <h2>Søndag</h2>
                    <div className='timeline'>
                        <b>12:00</b>
                        <p>Brunch</p>
                    </div>
                </section>
                <section className='section-with-image toastmaster-section'>
                    <img className='title-img left' src={valmue} alt='pynte blomst'/>
                    <img className='title-img' src={valmue} alt='pynte blomst'/>
                    <h1>Toastmaster</h1>
                    <p>Hvis du vil holde en tale, eller har andre indslag, så snak med toastmaster Laura:</p>

                    <div className='icon'>
                        <img src={phone} alt='phone icon'/>
                        <p>25 47 96 04</p>
                    </div>
                    <div className='icon'>
                        <img src={mail} alt='email icon'/>
                        <p>laurakaae@gmail.com</p>
                    </div>
                </section>

                {invitation && existingRsvp.length > 1 && <section className='section-with-image rsvp'>
                    <div className='rsvp-img-wrap'>
                        <img src={twig_04} alt='image of a flower'/>
                        <h1>R.S.V.P</h1>
                        <img src={twig_04} alt='image of a flower'/>
                    </div>
                    <h2>Vi har modtaget jeres RSVP.</h2>
                    <p>Mange tak for svaret.</p>
                    {existingRsvp
                        .find(e => e.question_id === 'can_come').answer === 'Ja'
                        ? `Vi glæder os enormt meget til at se ${invitation.invited_people.length === 1 ? 'dig' : 'jer'}!`
                        : 'Vi håber alligevel vi ses snart. Alt vel!'
                    }
                </section>}
                {invitation.invited_people && existingRsvp.length <= 1 && <section className='section-with-image rsvp'>
                    <div className='rsvp-img-wrap'>
                        <img src={twig_04} alt='image of a flower'/>
                        <h1>R.S.V.P</h1>
                        <img src={twig_04} alt='image of a flower'/>
                    </div>
                    {invitation && existingRsvp.length > 3 && <>
                        <h2>Vi har modtaget jeres RSVP.</h2>
                        <p>Mange tak for svaret. Hvis {invitation.invited_people.length === 1 ? 'du' : 'i'} vil, kan
                            RSVP indsendes igen med nye svar.</p>
                        <br/>
                        <br/>
                    </>}
                    {renderQuestion('can_come',
                        'Kan du komme?',
                        answers.can_come,
                        'binary'
                    )}
                    {renderQuestion('overnights',
                        'Vil du gerne overnatte på den glamourøse camping plads?',
                        answers.overnights,
                        'binary',
                        ['Ja! Både fredag og lørdag', 'Ja, men kun lørdag', 'Nej'],
                        null,
                        'I skal selv medbringe telt'
                    )}
                    {renderQuestion('needs_parking',
                        'Skal du bruge en parkeringsplads?',
                        answers.needs_parking,
                        'binary'
                    )}
                    {renderQuestion('food_preference',
                        'Lever du som vegetar, veganer eller socialist - eller er der mad som du ikke kan tåle, så skriv det endeligt her:',
                        answers.food_preference,
                        'text',
                        null,
                        invitation.invited_people.length > 1 ? 'Hvis et navn ikke bliver nævnt, antager vi de kan spise det hele.' : null
                    )}
                    {invitation.invited_people.length > 1 ? renderQuestion('drinks_alcohol',
                        'Er der nogen af jer som ikke drikker alkohol? (vælg dem som ikke drikker)',
                        answers.drinks_alcohol,
                        'people'
                    ) : renderQuestion(
                        'drinks_alcohol',
                        'Er du alkoholfri?',
                        answers.drinks_alcohol,
                        'binary'
                    )}
                    {renderQuestion('sunday_brunch',
                        'Vil du være med til brunch om søndagen?',
                        answers.sunday_brunch,
                        'binary'
                    )}

                    <input type='button' value='indsend' className='submit-btn' onClick={_ => getRsvp(invitation.id)}/>
                </section>}
                <img className='pynt-bot' src={blomsterBund} alt='blomster_pynte_billede'/>
            </div>
            {existingRsvp.length > 1 && <div className='has-rsvp'>
                <p>{invitation.invited_people.length > 1 ? 'I' : 'Du'} har svaret på RSVP. Mange tak :-)</p>
            </div>}
        </div>
    )
}
