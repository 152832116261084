import axios from 'axios';

const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000',
    timeout: 280000
});

http.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        const expires = localStorage.getItem('tokenExpires')
        if (expires) {
            if (new Date(expires) < new Date()) {
                userAPI.logout();
                window.location = '/logout';
                throw new axios.Cancel('Token expired. Moving to login.');
            }
        }
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
});

const easyUnpack = ({data}) => data;

const easyCatch = (err) => {
    if (err.err) {
        console.error(err)
    } else if (err.response.status === 401) {
        userAPI.logout();
        window.location = '/login'
    }
    throw err;
};

export const userAPI = {
    login(username, password) {
        return http.post('/authenticate', {
            username,
            password
        })
            .then(easyUnpack)
            .catch(easyCatch)
    },
    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpires')
    }
};

export const productAPI = {
    createProduct(product_name, artist, price, unique, height, width, description) {
        return http.post('/product', {
            product_name, artist, price, unique, height, width, description
        }).then(easyUnpack)
    },
    deleteProduct(productId) {
        return http.post('/product/delete', {
            product_id: productId
        }).then(easyUnpack)
    },
    addImageToProduct(productId, img_data, order = 1, image_name) {
        return http.post('/product/image', {
            product_id: productId, img_data, order, image_name
        }).then(easyUnpack)
    },
    listAllProducts() {
        return http.get('/product').then(easyUnpack)
    },
    getProduct(product_id) {
        return http.get(`/product/${product_id}`).then(easyUnpack)
    },
    fetchRawImages() {
        return http.get('/product/raw-images').then(easyUnpack)
    }
};

export const weddingAPI = {
    getOverview() {
        return http.get('/wedding/overview').then(easyUnpack)
    },
    getInvitationFromToken(token) {
        return http.get('/wedding/invitation', {
            params: {
                token
            }
        }).then(easyUnpack)
    },
    createRsvp(invitation_id, question_id, answer) {
        return http.post('/wedding/rsvp', {
            invitation_id, question_id, answer
        })
    },
    getRsvp(invitation_id) {
        return http.get('/wedding/rsvp', {
            params: {invitation_id}
        }).then(easyUnpack)
    }
};

export const frontpageImageAPI = {
    createImage(image_name, alt_text, image_data) {
        return http.post('/frontpage-image', {
            image_name, alt_text, image_data
        }).then(easyUnpack)
    },
    listImages() {
        return http.get('/frontpage-image').then(easyUnpack)
    }
}
